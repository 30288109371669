@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.control-dots{
    height: 70px;
    width: 300px !important;
    left: 50%;
    position: fixed;
    margin-left: -150px !important;
    bottom:50px !important;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(118, 119, 117, 0.61);
}
.carousel .control-dots .dot {
    height: 30px;
    width:30px;
    border-radius: 15px;
}
.carousel-item {
    height: 1050px;
    width: 2000px;
    margin-left: -60px;
}
iframe {
    padding: 0;
}
.control-arrow {
    background: rgba(102, 102, 102, 0.555) !important;
    height: 100px;
    width: 100px !important;
    top: 450px !important;
   
    border-radius: 50px;
    opacity: 1 !important;
    background-image: url('../../assets/images/arrow-right.png') !important;
    background-repeat: no-repeat !important;
    background-size:50%!important;
    background-position: center !important;
    &::before{
        opacity: 0 !important;
    }
}
.control-prev{
    left: 30px!important;
}

.control-next {
    right: 30px!important;
    transform: rotate(180deg);
}
