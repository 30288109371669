@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.chart-title {
   position     : relative;
   display      : flex;
   color        : #fff;
   font-size    : 18px;
   margin-bottom: 10px;
   padding-left: 30px;
}

.chart-title .title-point {
   content      : '';
   width        : 20px;
   height       : 20px;
   background   : #3680f8;
   border-radius: 50%;
   margin-right: 10px;
} 
.count {
width: 200px;
font-size: 30px; 
color: #fff;
text-align: center;
position: absolute;
top:120px;
left: 50%;
margin-left: -100px;

}

