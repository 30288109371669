@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';

.call-type {
  color        : #fff;
  width        : 90%;
  margin       : 0 auto;
  font-size    : 30px;
  line-height  : 62px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.233);
  display: flex;
  justify-content: space-between;
}


ul {
  margin-top   : 5px;
  padding-right: 10px;

  li {
    font-size    : 20px;
    color        : rgba(255, 255, 255, 0.822);
    padding-left : 20px;
    line-height  : 30px;
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
  }
}

.list-empty{
  color: #fff;
  font-size: 15px;
  padding: 20px;
}