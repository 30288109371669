@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.digital-flop {
  transform-origin: 0% 0%;
  zoom            : 1
}

.digital-title {
  color        : rgba(255, 255, 255, 0.9);
  font-size    : 24px;
  margin-bottom: 15px;
}

.digital-content {
  display        : flex;
  justify-content: flex-start;
  align-items    : baseline;
  font-size      : 54px;
  color          : #05d380;
  font-weight    : bold;

  .digital-suffix {
    font-size  : 24px;
    margin-left: 10px;
    color      : rgba(255, 255, 255, 0.9);
  }
}