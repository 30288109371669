@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.time-period-selector {
  line-height: 45px;
  color      : #fff;
  display    : flex;
  font-size  : 20px;
  margin: 25px 0  25px 0;
  padding-left: 30px;
}

.time-period-selector-item {
  cursor     : pointer;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 19px;
  &.active {
    border-radius: 4px;
    background-color:#3680f8 ;
    color: rgb(231, 231, 231);
  }
}

a {
  text-decoration: none;
}
.custom-button {
  cursor: pointer;
  margin-left: 10px;
  padding-left: 10px;
  padding-right:10px;
  border-radius: 4px;
  &.active {
    background: #3680f8;
  }
}

.custom-select-panel{
  height: 100px;
  width: 300px;
  background: rgba(60, 61, 65, 0.822);
  position: absolute;
  top:40px;
  left: 10px;
  z-index: 999;
  border-radius: 4px;
  font-size: 19px;
}