body {
  font-size: 14px;
}

.normal-color {
  color: green;
}
.hide {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}
